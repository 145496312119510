var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m_professional"},[_c('m-title',{attrs:{"title":"2023年度专业科目"}}),_c('div',{staticClass:"card_panel"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.Statistics.keChengShu))]),_c('div',{staticClass:"label"},[_vm._v("考试总数")])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.Statistics.daiKaoShi))]),_c('div',{staticClass:"label"},[_vm._v("待考试")])]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.Statistics.kaoShiTongGuo))]),_c('div',{staticClass:"label"},[_vm._v("考试通过")])])]),_c('m-title',{attrs:{"title":"专业课在线考试"}}),_c('div',{staticClass:"courseList"},[_c('capsule-tab',{attrs:{"list":_vm.List},on:{"click":_vm.selectCapsule},model:{value:(_vm.selectTab),callback:function ($$v) {_vm.selectTab=$$v},expression:"selectTab"}}),_c('div',{staticClass:"list"},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-left"},[_c('div',{staticClass:"circle",style:({
                border:
                  item.status == 1 && item.if_qualified == 0
                    ? '3px solid #B50413'
                    : '3px solid #B5BCC9',
              })}),_c('div',{staticStyle:{"margin":"auto 10px"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.courseName))]),_c('div',{staticClass:"achievement"},[_vm._v("考试成绩："+_vm._s(item.testScore)+"分")])])]),_c('div',{class:item.status == 1 && item.if_qualified == 0
                ? ''
                : 'disabled-link',on:{"click":function($event){return _vm.handlerExamination(item)}}},[_c('div',{staticClass:"button"},[_vm._v("去考试")])])])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }